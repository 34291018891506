import { Container, Typography, Box, Link, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: "white",
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
    backgroundImage: "linear-gradient(135deg, #667eea 0%, #764ba2 100%)",
    color: "white",
}));

const StyledLink = styled(Link)(({ theme }) => ({
    color: "white",
    textDecoration: "none",
    "&:hover": {
        textDecoration: "underline",
        color: "#ddd",
    },
}));

const StyledList = styled("ul")(({ theme }) => ({
    listStyleType: "none",
    paddingLeft: theme.spacing(2),
    "& li::before": {
        content: '"– "',
        paddingRight: theme.spacing(1),
    },
}));

const App = () => {
    return (
        <StyledContainer maxWidth="md">
            <Typography variant="h4" component="h1" gutterBottom>
                Титан Консалтинг
            </Typography>
            <Typography variant="h5" component="h2" color="text.secondary">
                Проекты
            </Typography>
            <Box sx={{ my: 2 }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 2,
                        marginTop: 2,
                    }}
                >
                    <img
                        src="clouddefender.jpg"
                        alt="Cloud Defender"
                        style={{ width: "100%", borderRadius: "8px" }}
                    />
                </Box>
                <Typography variant="body1" component="div">
                    В настоящее время за счет собственных средств реализуется
                    проект по разработке ПО для визуализации облачной
                    инфраструктуры и анализа политики безопасности в облачной
                    среде с помощью искусственного интеллекта (AI).
                </Typography>
            </Box>
            <Typography
                variant="h5"
                component="h2"
                color="text.secondary"
                mt="1rem"
            >
                Направления деятельности организации
            </Typography>
            <Typography variant="body1" component="div">
                <StyledList>
                    <li>
                        Консультационные услуги в сфере компьютерной
                        безопасности
                    </li>
                    <li>
                        Разработка ПО для организаций-заказчиков различных форм
                        собственности
                    </li>
                    <li>
                        Разработка ПАК для организаций-заказчиков различных форм
                        собственности
                    </li>
                    <li>
                        Аудит информационных систем в области компьютерной
                        безопасности
                    </li>
                    <li>
                        Восстановление данных с мобильных устройств под
                        управлением iOS и Android
                    </li>
                </StyledList>
            </Typography>
            <Typography
                variant="h5"
                component="h2"
                color="text.secondary"
                mt="1rem"
            >
                О компании
            </Typography>
            <Box sx={{ my: 2 }}>
                <Typography variant="body1">
                    ООО «Титан Консалтинг»
                    <br />
                    Телефон:{" "}
                    <StyledLink href="tel:+79109011400">
                        +7 910 901-14-00
                    </StyledLink>
                    <br />
                    Электропочта:{" "}
                    <StyledLink href="mailto:izryadnov.p@yandex.ru">
                        izryadnov.p@yandex.ru
                    </StyledLink>
                    <br />
                    Юридический адрес: 390006, Рязанская область, город Рязань,
                    Солотчинское ш, д. 11, помещ. н20 офис 11
                    <br />
                    ИНН: 6234191344, КПП: 623401001, ОГРН: 1206200008123
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 2,
                        marginTop: 2,
                    }}
                >
                    <img
                        src="office.jpg"
                        alt="Офис"
                        style={{
                            width: "500px",
                            maxWidth: "100%",
                            borderRadius: "8px",
                        }}
                    />
                </Box>
            </Box>
            <Typography variant="caption" display="block" gutterBottom>
                © 2024 ООО «Титан Консалтинг»
            </Typography>
        </StyledContainer>
    );
};

export default App;
